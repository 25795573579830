
import axios from "axios";
import PanelCard from './PanelCard.vue';

export default {
    name: "panel-component",
    components: { PanelCard },
    props: {
        openType: {
            type: Boolean
        }
    },
    data() {
        return {
            active_tab: 'all',
            notification_list_all: [],
            openOfficialModal: false,
            officialMessage: {
                main_image: ''
            },
            channelList: [
                {
                    name: '美食',
                    img: 'img_food.jpg',
                    link: 'food',
                    color: '#FFB700'
                },
                {
                    name: '影視',
                    img: 'img_movie.jpg',
                    link: 'movie',
                    color: '#A530A9'
                },
                {
                    name: '旅遊',
                    img: 'img_travel.jpg',
                    link: 'travel',
                    color: '#52B788'
                },
                {
                    name: '親子',
                    img: 'img_child_mobile.jpg',
                    link: 'family',
                    color: '#3CD4DF'
                },
                {
                    name: '寵物',
                    img: 'img_pet.jpg',
                    link: 'pet',
                    color: '#FF7200'
                },
                {
                    name: '3C',
                    img: 'img_3c.jpg',
                    link: '3c',
                    color: '#12316B'
                },
                {
                    name: '身心靈',
                    img: 'img_spirit.jpg',
                    link: 'body-mind-spirit',
                    color: '#1C438B'
                },
                {
                    name: '美妝',
                    img: 'img_makeup.jpg',
                    link: 'beauty',
                    color: '#FF89A5'
                },
                {
                    name: '健康',
                    img: 'img_heathly.jpg',
                    link: 'health',
                    color: '#000'
                },
                {
                    name: '職涯',
                    img: 'img_business.jpg',
                    link: 'career',
                    color: '#363636'
                },
                {
                    name: '住家',
                    img: 'img_home.jpg',
                    link: 'home',
                    color: '#D8C186'
                },
                {
                    name: '私康',
                    img: 'img_r18.png',
                    link: 'adult',
                    color: '#7354F8'
                }
            ],
            notifyShow: false,
        }
    },
    computed: {
        is_login() {
            return this.$store.state.login.login_status;
        },
        member_data() {
            return this.$store.state.login.member_data;
        },
        member_token() {
            return this.$store.state.login.pix_member_token.member_token;
        },
        // lv2 會員 member_name 為 null，識別是用 member_uniqid
        // lv4 會員才有 member_name
        has_member_name() {
            return this.member_data.member_name !== null;
        },
        link_blog() {
            if (this.has_member_name) {
                return `https://${this.member_data.member_name}.pixnet.net/blog`;
            } else {
                return `https://www.pixnet.net/pcard/${this.member_data.member_uniqid}/profile/blog`;
            }
        },
        link_collection() {
            let urlKey = this.has_member_name ? this.member_data.member_name : this.member_data.member_uniqid;
            return `https://www.pixnet.net/pcard/${urlKey}/mycollection/folders`;
        },
        notification_list_all_data() {
            return this.notification_list_all;
        },
        notification_list_official() {
            return this.notification_list_all.filter(item => item.type === "OFFICIAL");
        },
        has_unread() {
            return this.notification_list_all.find(item => item.is_read === false) !== undefined;
        },
        app_direct() {
            return this.$store.state.lightbox.app_direct;
        },
        safari_InnerHeight() {
            return this.$store.state.safariInnerHeight;
        }
    },
    methods: {
        readMessage(message) {
            if (!message.is_read) {
                this.readNotifications(message.id);
            }
            let isReadIdx = this.notification_list_all.findIndex(item => item.id === message.id);
            this.notification_list_all[isReadIdx].is_read = true;
            if (message.type === 'NORMAL') {
                location.href = message.action_link;
            } else if (message.type === 'OFFICIAL') {
                this.showOfficialNotification(message.id);
            }
        },
        goLogin() {
            // const url = window.location.host;
            // location.href = "https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F" + url + "%3Fversions%3D2023";

            location.href = `https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2F${window.location.host}${window.location.pathname}%3Fversions%3D2023`;
        },
        async getNotifications() {
            const API_url = "https://api.pixnet.cc/navbar/v1/notifications";
            try {
                const data = await axios({
                    method: 'get',
                    url: API_url,
                    withCredentials: true
                });
                if (data.status === 200 && !data.error) {
                    this.notification_list_all = data.data.data;
                    this.$emit('has-unread', this.has_unread);
                } else {
                    return Promise.reject(data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async readNotifications(id) {
            const API_url = `https://api.pixnet.cc/navbar/v1/notification/${id}/read`;
            try {
                const data = await axios({
                    method: 'post',
                    url: API_url,
                    withCredentials: true
                });
                if (data.status === 200 && !data.error) {
                    this.getNotifications();
                } else {
                    return Promise.reject(data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        // 讀取官方消息api 有打通 資料還沒有串燈箱
        async showOfficialNotification(id) {
            document.cookie = 'XSRF-TOKEN=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            const API_url = `https://api.pixnet.cc/navbar/v1/notification/${id}`;
            try {
                const data = await axios({
                    method: 'get',
                    url: API_url,
                    withCredentials: true
                });
                if (data.status === 200 && !data.error) {
                    this.openOfficialModal = true;
                    this.officialMessage = data.data.data;
                    if (window.screen.width > 1024) {
                        this.$emit('close-panel');
                    }
                } else {
                    return Promise.reject(data);
                }
            } catch (e) {
                console.log(e);
            }
        },
        async clickAllRead() {
            try {
                let res =await  axios.get('https://api.pixnet.cc/navbar/v1/notifications', {
                    params: {
                        read_all: 1
                    },
                    withCredentials: true
                });

                if ( res.status === 200 && !res.data.error) {
                    this.notification_list_all = JSON.parse(JSON.stringify(res.data.data))
                } else {
                    return Promise.reject(res);
                }

            } catch(e) {
                console.log(e);
            }
        },
        goCreateArticle() {
            location.href = this.is_login ?
                'https://panel.pixnet.cc/#/create-article' :
                'https://member.pixnet.cc/login/openid?openid=https://member.pixnet.cc/login&easy_login=1&done=https%3A%2F%2Fpanel.pixnet.cc%2F%23%2Fcreate-article';
        },
        closePanel() {
            this.$emit('close-panel');
        }
    },
    watch: {
        is_login: function () {
            if (this.is_login) {
                this.getNotifications();
            }
        },
        has_unread(val) {
            if (!this.has_unread) {
                this.$emit('has-unread', val);
            }
        }
    },
    mounted() {
        if (this.is_login) {
            this.getNotifications();
        }
    },
    destroyed() {
        document.body.classList.remove('body-lock');
    }
}
