
export default {
    name: "toast-container",
    data() {
        return {
            public_toast: ''
        }
    },
    computed: {
        view_data() {
            return this.$store.state.lightbox.view_data;
        },
        member_name() {
            return this.$store.state.login.member_data.member_name;
        },
        member_unique_id() {
            return this.$store.state.login.member_data.member_uniqid;
        },
        // lv2 會員 member_name 為 null，識別是用 member_uniqid
        // lv4 會員才有 member_name
        has_member_name() {
            return this.member_name !== null;
        },
        link_collection() {
            let urlKey = this.has_member_name ? this.member_name : this.member_unique_id;
            return `https://www.pixnet.net/pcard/${urlKey}/mycollection/folders`;
        },
        block_display_name() {
            return this.$store.state.pcard.block_display_name;
        }
    },
    methods: {
        openToastElement(){
            this.public_toast.show();
        }
    },
    mounted() {
        this.public_toast = this.$getCollectToast(this.$refs.public_toast);
    }
}
